<template>
    <div>
        <header-portrate />
        <div style="margin-bottom:70px;">
            <tabs-comp :tabs="tabs" />
            <v-row class="mainrow m-2" :style="`background:#d7b10015;direction:`+lang.dir+`;margin-top:100px !important;border:1px solid #555;border-radius:10px`">
                <v-col cols="12" md="4" sm="12">
                    <label>{{lang.api_token}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="api_token" 
                        readonly
                    ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.status}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="status[`name_`+lang.langname]" 
                        readonly
                        :style="status.style"
                    ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.expire_date}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="expire_date" 
                        readonly
                    ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.send_attachment}}</label>
                    <b-form-input
                        class="inborder"
                        v-model="allow_attached[`name_`+lang.langname]" 
                        readonly
                        :style="allow_attached.style"
                    ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" @click="openIt()" v-b-toggle.scanQR>
                    Scan QR Code for WhatsApp Connect
                </v-col>
                <v-col cols="12" md="6" sm="12" v-for="(day,index) in days" :key="index">
                    <v-row>
                        <v-col cols="6">
                            <label>{{day[`name${lang.langname}`]}} {{lang.working_time_star}}</label>
                            <b-form-input type="time" class="inborder" v-model="time_start[day.short]" />
                        </v-col>
                        <v-col cols="6">
                            <label>{{day[`name${lang.langname}`]}} {{lang.working_time_end}}</label>
                            <b-form-input type="time" class="inborder" v-model="time_end[day.short]" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <ScanQR ref="scanQRCode" />
        <Footer />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue'
import Footer from '@/components/Footer.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios'
import ScanQR from '@/components/scanQR.vue'
export default{
    components: {
        HeaderPortrate, Footer,
        TabsComp,
        ScanQR
    },
    data() {  
        return {
            time_start: {
                mon: '09:00',
                tue: '09:00',
                wed:'09:00',
                thu: '09:00',
                fri: '00:00',
                sat: '09:00',
                sun: '09:00'
            },
            time_end: {
                mon: '18:00',
                tue: '18:00',
                wed:'18:00',
                thu: '18:00',
                fri: '00:00',
                sat: '18:00',
                sun: '18:00'
            },
            api_token: '',
            status: {
                name_ar: 'متصل',
                name_en: 'Connected',
                style: 'background:green !important;color:#fff !important'
            },
            expire_date: '2024-10-05',
            allow_attached: {
                name_ar: 'نعم',
                name_en: 'Yes',
                style: 'background:green !important;color:#fff !important'
            },
        } 
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        days: function(){
            return [
                {namear: 'السبت', nameen: 'Sat',short: 'sat'},
                {namear: 'الأحد', nameen: 'Sun',short: 'sun'},
                {namear: 'الاثنين', nameen: 'Mon',short: 'mon'},
                {namear: 'الثلاثاء', nameen: 'Tue',short: 'tue'},
                {namear: 'الاربعاء', nameen: 'Wed',short: 'wed'},
                {namear: 'الخميس', nameen: 'Thu',short: 'thu'},
                {namear: 'الجمعة', nameen: 'Fri',short: 'fri'},
            ]
        },
        tabs: function(){
        
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
           
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                // // console.log("value",license);
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                      if(alicense[value.perms[0]] || value.perms[0] == 'any')
                          t.push(value)
                    }
                }
            })
        
            return t;
      },
    },
    methods: {
        openIt(){
            this.$refs.scanQRCode.api_token = this.api_token;
        },
        async getWhatsSettings(){
            const post = new FormData();
            post.append("type","whatsSettings");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[id]",0)
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            if(response && response.data && response.data.results){
                this.api_token = response.data.results.data.api_token
                this.status = response.data.results.data.status
                this.expire_date = response.data.results.data.expire_date
                this.allow_attached = response.data.results.data.allow_attachment
            }
        }
    },
    created(){
        this.getWhatsSettings()
    }

}
</script>
